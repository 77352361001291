import request from '@/utils/request'
const serivceBase = require('@/config/serviceConfig')

// 同步兑换码（特殊兑换码）-获取兑换码信息
export function getSpecialInfo(data) {
  return request({
    url: `${serivceBase.IMS}/redeemCodeStream/getRedeemCodeForSpecial`,
    method: 'post',
    data
  })
}
// 兑换码兑换
export function syncVerify(data) {
  return request({
    url: `${serivceBase.IMS}/redeemCodeStream/verifyForSpecialRecharge`,
    method: 'post',
    data
  })
}
// 发送短信
export function sendCode(data) {
  return request({
    url: `${serivceBase.IMS}/redeemCodeStream/sendVerifyCodeForSpecialRecharge`,
    method: 'post',
    data
  })
}
